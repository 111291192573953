/* eslint no-unused-vars: 0 */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'
import style from './styles.css'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      xgrit: file(relativePath: { eq: "xgrit.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div className="intro" id="intro">
      <Container>
        <Row>
          <Col xl="10" lg="10" md="24" sm="24" xs="24">
            <Img
              fluid={data.xgrit.childImageSharp.fluid}
              alt=""
              className="intro-img"
            />
          </Col>
          <Col xl="14" lg="14" md="24" sm="24" xs="24">
            <div
              css={css`
                width: 100%;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                text-indent: 2em;
              `}
              className="intro-text"
            >
              <p>
                福州无锋网络科技有限责任公司是一家基于大数据的互联网科技创新企业。公司致力于IT技术服务领域，为用户提供软件系统开发解决方案、短信和企业身份验证解决方案、大数据分析解决方案。公司研发运营团队均来自于知名互联网技术人员，具有多年丰富的产品开发、运营、推广、营销经验。
              </p>
              <p>
                公司员工的平均年龄只有25岁左右，是一个拥有朝气、时刻散发着活力与创新的团队，是一个非常适合年轻人努力奋斗、实现自己梦想的团队。如果你也是一个有活力、有梦想、有激情的年轻人，欢迎您加入我们的团队！
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

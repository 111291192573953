import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Nav from '../components/nav'
import Intro from '../components/about/intro'
import Culture from '../components/about/culture'
import AboutUs from '../components/home/aboutUs'
import History from '../components/about/history'
import ContactUs from '../components/about/contactUs'
import Footer from '../components/footer'
import SEO from '../components/seo'

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      backgroundAbout: file(relativePath: { eq: "backgroundAbout.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div>
      <SEO title="关于我们" description="about" />
      {/* 背景图片 */}
      <div
        css={css`
          width: 100%;
          background: url(${data.backgroundAbout.childImageSharp.fluid.src})
            center center;
        `}
        className="aboutNav"
      >
        <Nav />

        <div
          css={css`
            display: inline-block;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            line-height: 84px;
            width: 100%;
            text-align: center;
            line-height: 78px;
          `}
          className="nav-title"
        >
          关于我们
        </div>
      </div>
      {/* 公司简介 */}
      <Intro />
      {/* 企业文化 */}
      <Culture />
      {/* 关于我们 */}
      <AboutUs />
      {/* 发展历程 */}
      <History />
      {/* 联系我们 */}
      <ContactUs />
      {/* 页脚 */}
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import { Container, Row, Col } from 'react-bootstrap'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      vector: file(relativePath: { eq: "vector.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div
      css={css`
        font-family: PingFang-SC-Bold, PingFang-SC;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      `}
      id="history"
      className="history"
    >
      <div
        css={css`
          font-weight: bold;
        `}
        className="history-title"
      >
        发展历程
      </div>
      <Container className="history-content">
        <Row>
          <Col
            xl="4"
            lg="4"
            md="24"
            sm="24"
            xs="24"
            className="step"
            css={css`
              margin: 0 1.6%;
              :hover {
                background: linear-gradient(
                  180deg,
                  rgba(246, 179, 82, 1) 0%,
                  rgba(255, 147, 115, 1) 100%
                );
                box-shadow: 0px 2px 10px 0px rgba(232, 222, 220, 1);
                border-radius: 6px;
                div {
                  color: rgba(255, 255, 255, 1);
                }
              }
            `}
          >
            <div
              css={css`
                font-size: 24px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                line-height: 33px;
              `}
              className="step-left"
            >
              2014
            </div>
            <div
              css={css`
                width: 3px;
                height: 3px;
                background: rgba(151, 151, 151, 1);
                border-radius: 50%;
              `}
              className="step-dot"
            />
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                text-align: left;
              `}
              className="step-right"
            >
              福州无锋网络科技有限责任公司初创
            </div>
          </Col>

          <Col
            xl="4"
            lg="4"
            md="24"
            sm="24"
            xs="24"
            className="step"
            css={css`
              margin: 0 1.6%;
              :hover {
                background: linear-gradient(
                  180deg,
                  rgba(246, 179, 82, 1) 0%,
                  rgba(255, 147, 115, 1) 100%
                );
                box-shadow: 0px 2px 10px 0px rgba(232, 222, 220, 1);
                border-radius: 6px;
                div {
                  color: rgba(255, 255, 255, 1);
                }
              }
            `}
          >
            <div
              css={css`
                font-size: 24px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                line-height: 33px;
              `}
              className="step-left"
            >
              2015
            </div>
            <div
              css={css`
                width: 3px;
                height: 3px;
                background: rgba(151, 151, 151, 1);
                border-radius: 50%;
              `}
              className="step-dot"
            />
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                text-align: left;
              `}
              className="step-right"
            >
              正式接入短信验证业务，参与构建认证体系标准
            </div>
          </Col>

          <Col
            xl="4"
            lg="4"
            md="24"
            sm="24"
            xs="24"
            className="step"
            css={css`
              margin: 0 1.6%;

              :hover {
                background: linear-gradient(
                  180deg,
                  rgba(246, 179, 82, 1) 0%,
                  rgba(255, 147, 115, 1) 100%
                );
                box-shadow: 0px 2px 10px 0px rgba(232, 222, 220, 1);
                border-radius: 6px;
                div {
                  color: rgba(255, 255, 255, 1);
                }
              }
            `}
          >
            <div
              css={css`
                font-size: 24px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                line-height: 33px;
              `}
              className="step-left"
            >
              2016
            </div>
            <div
              css={css`
                width: 3px;
                height: 3px;
                background: rgba(151, 151, 151, 1);
                border-radius: 50%;
              `}
              className="step-dot"
            />
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                text-align: left;
              `}
              className="step-right"
            >
              提供行业软件定制开发业务
            </div>
          </Col>

          <Col
            xl="4"
            lg="4"
            md="24"
            sm="24"
            xs="24"
            className="step"
            css={css`
              margin: 0 1.6%;
              :hover {
                background: linear-gradient(
                  180deg,
                  rgba(246, 179, 82, 1) 0%,
                  rgba(255, 147, 115, 1) 100%
                );
                box-shadow: 0px 2px 10px 0px rgba(232, 222, 220, 1);
                border-radius: 6px;
                div {
                  color: rgba(255, 255, 255, 1);
                }
              }
            `}
          >
            <div
              css={css`
                font-size: 24px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                line-height: 33px;
              `}
              className="step-left"
            >
              2018
            </div>
            <div
              css={css`
                width: 3px;
                height: 3px;
                background: rgba(151, 151, 151, 1);
                border-radius: 50%;
              `}
              className="step-dot"
            />
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                text-align: left;
              `}
              className="step-right"
            >
              全面开展对公验证服务
            </div>
          </Col>

          <Col
            xl="4"
            lg="4"
            md="24"
            sm="24"
            xs="24"
            className="step"
            css={css`
              margin: 0 1.6%;

              :hover {
                background: linear-gradient(
                  180deg,
                  rgba(246, 179, 82, 1) 0%,
                  rgba(255, 147, 115, 1) 100%
                );
                box-shadow: 0px 2px 10px 0px rgba(232, 222, 220, 1);
                border-radius: 6px;
                div {
                  color: rgba(255, 255, 255, 1);
                }
              }
            `}
          >
            <div
              css={css`
                font-size: 24px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: rgba(51, 51, 51, 1);
                line-height: 33px;
              `}
              className="step-left"
            >
              2020
            </div>
            <div
              css={css`
                width: 3px;
                height: 3px;
                background: rgba(151, 151, 151, 1);
                border-radius: 50%;
              `}
              className="step-dot"
            />
            <div
              css={css`
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                text-align: left;
              `}
              className="step-right"
            >
              通过ISO/ICE 27001:2013信息安全管理体系认证
            </div>
          </Col>
        </Row>{' '}
        <div
          css={css`
            background-color: #d9d9d9;
            position: absolute;
          `}
          className="history-tieku"
        />
      </Container>

      <div
        css={css`
          width: 100%;
          background: url(${data.vector.childImageSharp.fluid.src}) center
            center;
          background-size: 100% 26px;
          height: 26px;
          margin-top: 50px;
        `}
      />
    </div>
  )
}

import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "react-bootstrap"

export default () => (
  <div
    css={css`
      width: 100%;
      background: rgba(247, 245, 243, 1);
      text-align: center;
    `}
    className="aboutUs"
  >
    <Container
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      `}
    >
      <Row
        css={css`
          width: 100%;
        `}
      >
        <Col xl="7" lg="7" md="7" sm="7" xs="7">
          <div
            css={css`
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            `}
            className="step"
          >
            <span className="text1">2014</span>
            <span className="text2">年</span>
          </div>
          <div className="text3">注册成立于2014年</div>
        </Col>
        <div
          css={css`
            width: 1px;
            background-color: #b1a7a7;
            opacity: 0.8;
          `}
          className="separator"
        />
        <Col xl="9" lg="9" md="9" sm="9" xs="9">
          <div
            css={css`
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            `}
            className="step"
          >
            <div>
              <span className="text1">15</span>
              <span className="text2">个</span>
            </div>
            <div className="text3">有10多个自主开发项目</div>
          </div>
        </Col>
        <div
          css={css`
            width: 1px;
            background-color: #b1a7a7;
            opacity: 0.8;
          `}
          className="separator"
        />
        <Col xl="7" lg="7" md="7" sm="7" xs="7">
          <div
            css={css`
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            `}
            className="step"
          >
            <div>
              <span className="text1">150</span>
              <span className="text2">家</span>
            </div>
            <div className="text3">服务企业100多家</div>
          </div>
        </Col>
      </Row>{" "}
    </Container>
  </div>
)

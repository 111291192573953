import React from 'react'
import { css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      culture_1: file(relativePath: { eq: "culture_1.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      culture_2: file(relativePath: { eq: "culture_2.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      culture_3: file(relativePath: { eq: "culture_3.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      culture_4: file(relativePath: { eq: "culture_4.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)
  return (
    <div
      className="culture"
      id="culture"
      css={css`
        font-family: PingFang-SC-Bold, PingFang-SC;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      `}
    >
      <Container>
        <div
          className="culture-title"
          css={css`
            font-weight: bold;
          `}
        >
          企业文化
        </div>
        <Row
          css={css`
            display: flex;
            margin-top: 80px;
          `}
        >
          <Col
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            className="step"
            css={css`
              padding-right: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                margin-bottom: 10px;
                width: 100%;
              `}
            >
              <Img
                fluid={data.culture_1.childImageSharp.fluid}
                alt=""
                className="img"
              />
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(189, 198, 209, 1);
                  flex-grow: 1;
                  text-align: right;
                `}
                className="serial"
              >
                01
              </div>
            </div>
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                line-height: 22px;
                text-align: left;
              `}
              className="step-title"
            >
              行为指引
            </div>
            <div
              css={css`
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                line-height: 17px;
                margin: 15px 0 0;
                text-align: left;
              `}
              className="text"
            >
              创新进取 团队合作 注重效率
              <br />公平公正 至诚守信 志存高远
            </div>
            <div
              css={css`
                width: 100%;
                height: 1px;
                background-color: #d9d9d9;
              `}
              className="delimiter"
            />
          </Col>
          <Col
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            className="step"
            css={css`
              padding-left: 15px;
            `}
          >
            <div
              css={css`
                display: flex;
                margin-bottom: 10px;
                width: 100%;
              `}
            >
              <div>
                <Img
                  fluid={data.culture_2.childImageSharp.fluid}
                  alt=""
                  className="img"
                />
              </div>
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(189, 198, 209, 1);
                  flex-grow: 1;
                  text-align: right;
                `}
                className="serial"
              >
                02
              </div>
            </div>
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                line-height: 22px;
                text-align: left;
              `}
              className="step-title"
            >
              价值观
            </div>
            <div
              css={css`
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                line-height: 17px;
                margin: 15px 0 0;
                text-align: left;
              `}
              className="text"
            >
              以客户为中心以进取者为本
              <br />以协作创高效坚持自我批判
            </div>
            <div
              css={css`
                width: 100%;
                height: 1px;
                background-color: #d9d9d9;
              `}
              className="delimiter"
            />
          </Col>
          <Col
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            className="step"
            css={css`
              padding-right: 8px;
            `}
          >
            <div
              css={css`
                display: flex;
                margin-bottom: 10px;
                width: 100%;
              `}
            >
              <div>
                <Img
                  fluid={data.culture_3.childImageSharp.fluid}
                  alt=""
                  className="img"
                />
              </div>
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(189, 198, 209, 1);
                  flex-grow: 1;
                  text-align: right;
                `}
                className="serial"
              >
                03
              </div>
            </div>
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                line-height: 22px;
                text-align: left;
              `}
              className="step-title"
            >
              愿景
            </div>
            <div
              css={css`
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                line-height: 17px;
                margin: 15px 0 0;
                text-align: left;
              `}
              className="text"
            >
              以品质提升价值
              <br />成为社会信用体系建设行业的标杆
            </div>
            <div
              css={css`
                width: 100%;
                height: 1px;
                background-color: #d9d9d9;
              `}
              className="delimiter"
            />
          </Col>
          <Col
            xl="6"
            lg="6"
            md="12"
            sm="12"
            xs="12"
            className="step"
            css={css`
              padding-left: 15px;
            `}
          >
            <div
              css={css`
                display: flex;
                margin-bottom: 10px;
                width: 100%;
              `}
            >
              <div>
                <Img
                  fluid={data.culture_4.childImageSharp.fluid}
                  alt=""
                  className="img"
                />
              </div>
              <div
                css={css`
                  font-family: Brig;
                  color: rgba(189, 198, 209, 1);
                  flex-grow: 1;
                  text-align: right;
                `}
                className="serial"
              >
                04
              </div>
            </div>
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                line-height: 22px;
                text-align: left;
              `}
              className="step-title"
            >
              使命
            </div>
            <div
              css={css`
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                line-height: 17px;
                margin: 15px 0 0;
                text-align: left;
              `}
              className="text"
            >
              让客户感到满意
              <br />成功履行承诺
            </div>
            <div
              css={css`
                width: 100%;
                height: 1px;
                background-color: #d9d9d9;
              `}
              className="delimiter"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site: file(relativePath: { eq: "site.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      EMail: file(relativePath: { eq: "EMail.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <div
      css={css`
        font-family: PingFang-SC-Bold, PingFang-SC;
        text-align: center;
      `}
      id="contactUs"
      className="contactUs"
    >
      <Container>
        <div
          css={css`
            font-weight: bold;
          `}
          className="contactUs-title"
        >
          联系我们
        </div>
        <Row
          css={css`
            width: 100%;
            margin-top: 60px;
            display: flex;
          `}
        >
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <Img
              fluid={data.EMail.childImageSharp.fluid}
              alt=""
              css={css`
                margin: 0px auto;
              `}
              className="contactUs-img"
            />
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                margin: 0 auto;
              `}
              className="contactUs-subhead"
            >
              邮箱
            </div>
            <div
              css={css`
                margin: 0 auto;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(173, 170, 174, 1);
              `}
              className="contactUs-content"
            >
              bd@x-grit.com
            </div>
          </Col>
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            <div>
              <Img
                fluid={data.site.childImageSharp.fluid}
                alt=""
                css={css`
                  margin: 0px auto;
                `}
                className="contactUs-img"
              />
            </div>
            <div
              css={css`
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
                margin: 0 auto;
              `}
              className="contactUs-subhead"
            >
              地址
            </div>
            <div
              css={css`
                margin: 0 auto;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(173, 170, 174, 1);
                text-align: left;
              `}
              className="contactUs-content"
            >
              福州仓山区金山大道橘园洲工业园区悦孚办公园区38号楼3楼307
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
